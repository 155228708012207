import { graphql } from "gatsby";
import parse from "html-react-parser";
import React from "react";

const BlogSimpleContent = ({ module }) => {
  return (
    <section className={`common-content bg-pattern lg:py-60 py-30${module?.extraClass ? " " + module.extraClass : ""}`}>
      <div className='w-[860px] m-auto text-center lgscreen:w-full px-20 fade-ani'>
        {
          module?.heading && (
            <div className='title-black'>
              <h3>{module.heading}</h3>
            </div>
          )
        }
        {
          module?.description && (
            <div className='content '>
              {parse(module.description)}
            </div>
          )
        }
        {
          module?.socialMedia?.length > 0 && (
            <div className="sicon ">
              <ul className="flex justify-center mt-30 space-x-5">
                {
                  module.socialMedia.map((item, index) => (
                    <li key={index.toString()}>
                      <a href={item?.url} target="_blank">
                        <img
                          src={item?.icon?.mediaItemUrl}
                          loading="lazy"
                          alt={item?.icon?.altText}
                        />
                      </a>
                    </li>
                  ))
                }
              </ul>
            </div>
          )
        }

      </div>
    </section>
  )
}

export default BlogSimpleContent;
export const BlogSimpleContentFragment = graphql`
  fragment BlogSimpleContentFragment on WpPage_Pagecontent_PageContent_BlogSimpleContent {
    id
    extraClass
    hideSection
    heading
    description
    socialMedia {
      icon {
        altText
        mediaItemUrl
      }
      url
    }
  }
`;