import ImageOpt from './ImageOpt';
import { motion, useInView } from 'framer-motion';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';

const itemsPerRow = 6;

const ExperienceListing = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  const [next, setNext] = useState(itemsPerRow);

  const handleMoreItems = () => {
    setNext(next + itemsPerRow);
  };

  const query = useStaticQuery(graphql`
    query {
      grid: allWpExperience(
        filter: { parentId: { eq: null } }
        sort: { fields: uri, order: ASC }
      ) {
        nodes {
          title
          excerpt
          uri
          featuredImage {
            node {
              altText
              gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
              mediaItemUrl
            }
          }
        }
      }
    }
  `);

  const experiences = query?.grid?.nodes || [];

  return (
    <section
      className={`small-img-grid no-pattern lg:py-120 py-30${
        module?.extraClass ? ' ' + module.extraClass : ''
      }`}
    >
      <div className="container-fluid">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={isInView || hasPlayed ? 'show' : 'hidden'}
          variants={container}
          className="flex flex-wrap lg:mx-minus-30 mx-0 gap-y-14 mdscreen:gap-8"
        >
          {experiences?.length > 0 &&
            experiences.slice(0, next).map((experienceItem, index) => (
              <motion.div
                variants={item}
                className="lg:w-4/12 w-full lg:px-15 px-0 fade-ani"
              >
                <Link to={experienceItem.uri}>
                  <div className="ig-bx">
                    <ImageOpt
                      imgData={experienceItem.featuredImage?.node.mediaItemUrl}
                      imgAlt={experienceItem.featuredImage?.node?.altText}
                      imgClass="w-full"
                      width={432}
                      height={420}
                    />
                    <div className="ig-info pr-30 pt-20">
                      <div className="title-black">
                        {experienceItem?.title && (
                          <h6 className="">{experienceItem.title}</h6>
                        )}
                        {experienceItem?.excerpt && (
                          <div className="content ">
                            {parse(experienceItem.excerpt)}
                          </div>
                        )}
                        {experienceItem?.uri && (
                          <div className="btn-custom flex flex-wrap items-center space-x-8 mt-20 ">
                            <span className="button btn-red-border">
                              Explore Experiences
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              </motion.div>
            ))}
        </motion.div>
        {next < experiences?.length && (
          <div className="btn-custom flex flex-wrap items-center justify-center space-x-8 lg:mt-70 mt-30 fade-ani">
            <button className="button btn-border" onClick={handleMoreItems}>
              <span>Load More Experiences</span>
              <img
                src="/images/btn-red-bg.png"
                loading="eager"
                alt="Load More Button"
              />
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default ExperienceListing;
export const ExperienceListingFragment = graphql`
  fragment ExperienceListingFragment on WpPage_Pagecontent_PageContent_ExperienceListing {
    id
    extraClass
    hideSection
  }
`;
