// Nested Components
import ImageWithContent from './ImageWithContent';
import RelatedTripInspiration from './RelatedTripInspiration';
import { motion, useInView } from 'framer-motion';
import { graphql } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';

const MonthlyEvents = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.2,
        staggerChildren: 0.05,
      },
    },
  };

  const aniItem = {
    hidden: { opacity: 0, y: 10 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  const [selectedMonth, setSelectedMonth] = useState(
    module?.months?.[0]?.monthName || 'Jan'
  );

  const onTabClick = (month) => {
    setSelectedMonth(month);
  };

  const renderEventContent = (event, eventIndex) => {
    switch (event?.__typename) {
      case 'WpPage_Pagecontent_PageContent_MonthlyEvents_months_events_Content_ImageWithContent': {
        return <ImageWithContent key={eventIndex.toString()} module={event} />;
      }
      case 'WpPage_Pagecontent_PageContent_MonthlyEvents_months_events_Content_RelatedTripInspiration': {
        return (
          <RelatedTripInspiration key={eventIndex.toString()} module={event} />
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <>
      <section
        className={`month-tabs${
          module?.extraClass ? ' ' + module.extraClass : ''
        }`}
      >
        <div className="container-fluid-xl bg-pattern py-30">
          {module?.months?.length > 0 && (
            <motion.div
              ref={ref}
              initial="hidden"
              animate={isInView || hasPlayed ? 'show' : 'hidden'}
              variants={container}
              className="month-info flex flex-wrap justify-between lgscreen:gap-x-5 mdscreen:gap-x-[0.75rem] lgscreen:justify-center lgscreen:gap-y-5 mdscreen:gap-y-[0.75rem] fade-ani"
            >
              {module?.months?.map((item, index) => (
                <motion.div
                  variants={aniItem}
                  key={index.toString()}
                  className={`circle-grid cursor-pointer${
                    selectedMonth === item?.monthName ? ' active' : ''
                  }`}
                  onClick={() => onTabClick(item?.monthName)}
                >
                  <span>{item.monthName}</span>
                </motion.div>
              ))}
            </motion.div>
          )}
        </div>
      </section>
      {module?.months?.map((item, index) => {
        if (item?.monthName !== selectedMonth) {
          return null;
        }
        if (!item?.events?.length || item?.events?.length === 0) {
          return (
            <div className="py-50 text-center">
              <p>No Event Found !</p>
            </div>
          );
        }
        return item?.events?.map((event) => {
          return event?.content?.map(renderEventContent);
        });
      })}
    </>
  );
};

export default MonthlyEvents;
export const MonthlyEventsFragment = graphql`
  fragment MonthlyEventsFragment on WpPage_Pagecontent_PageContent_MonthlyEvents {
    id
    extraClass
    hideSection
    months {
      monthName
      events {
        content {
          __typename
          ...MonthlyEventImageWithContentFragment
          ...MonthlyEventRelatedTripInspirationFragment
        }
      }
    }
  }
`;
