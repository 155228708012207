import { graphql } from "gatsby";
import parse from "html-react-parser";
import React from "react";

const TermsAndConditionsContent = ({ module }) => {
  return (
    <section className={`tnc-content lg:pt-60 lgscreen:mt-30 pt-0 lg:pb-120 pb-30 ${module?.extraClass ? " " + module.extraClass : ""}`}>
      <div className='w-[850px] m-auto text-center lgscreen:w-full px-20'>
        <div className="content fade-ani text-left">
          {module?.content && parse(module?.content)}
        </div>
      </div>
    </section>
  )
}

export default TermsAndConditionsContent;
export const TermsAndConditionsContentFragment = graphql`
  fragment TermsAndConditionsContentFragment on WpPage_Pagecontent_PageContent_TermsAndConditionsContent {
    id
    extraClass
    hideSection
    content
  }
`;