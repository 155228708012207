import { motion, useInView } from 'framer-motion';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';

const ImageBox = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const aniItem = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  return (
    <section
      className={`dc-img-grid lg:py-120 py-30${
        module?.extraClass ? ' ' + module.extraClass : ''
      }`}
    >
      <div className="container-fluid">
        <div className="flex flex-wrap lg:mx-minus-15 mx-0 gap-y-6 fade-ani">
          {module?.data?.map((item, index) => (
            <div
              className="lg:w-6/12 w-full lg:px-15 px-0"
              key={index.toString()}
            >
              <div className="dc-img-bx">
                <GatsbyImage
                  image={getImage(item?.image)}
                  alt={item?.image?.altText}
                />
              </div>
              <motion.div
                ref={ref}
                initial="hidden"
                animate={isInView || hasPlayed ? 'show' : 'hidden'}
                variants={container}
                className="dc-img-content pt-35 max-w-[523px] w-full"
              >
                <motion.div variants={aniItem} className="title-black">
                  <h5>{item?.heading}</h5>
                </motion.div>
                <motion.div variants={aniItem} className="content ">
                  {item?.description && parse(item?.description)}
                </motion.div>
                <motion.div
                  variants={aniItem}
                  className="btn-custom flex flex-wrap items-start space-x-8 mt-30 "
                >
                  <Link to={item?.link?.url} className="button btn-red-border">
                    {item?.link?.title}
                  </Link>
                </motion.div>
              </motion.div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ImageBox;
export const ImageBoxFragment = graphql`
  fragment ImageBoxFragment on WpPage_Pagecontent_PageContent_ImageBox {
    id
    extraClass
    hideSection
    data {
      heading
      description
      link {
        target
        title
        url
      }
      image {
        altText
        gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 500)
      }
    }
  }
`;
