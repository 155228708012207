import { graphql, Link } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const TimelessNews = ({ module }) => {
  const handleNextClickStay = () => {
    const swiper = document.querySelector('.awards-slider .swiper').swiper;
    swiper.slideNext();
  };
  const handlePrevClickStay = () => {
    const swiper = document.querySelector('.awards-slider .swiper').swiper;
    swiper.slidePrev();
  };

  return (
    <section
      className={`awards-slider bg-pattern4 lg:py-100 py-30${
        module?.extraClass ? ' ' + module.extraClass : ''
      }`}
    >
      <div className="container-fluid">
        <div className="section-title fade-ani">
          {module?.heading && (
            <div className="title-black">
              <h3>{module.heading}</h3>
            </div>
          )}
          {module?.description && (
            <div className="content ">{parse(module.description)}</div>
          )}
        </div>
        {module?.data?.length > 0 && (
          <div className="aw-slider-inner relative lg:mt-50 mt-25 laptop:px-10 mdscreen:px-30 ">
            <Swiper
              spaceBetween={30}
              loop={true}
              slidesPerView={3}
              allowTouchMove={true}
              grabCursor={true}
              speed={1000}
              navigation={false}
              modules={[Autoplay, Pagination, Navigation]}
              className="trip-slider"
              breakpoints={{
                100: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                500: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
            >
              {module.data?.map((item, index) => (
                <SwiperSlide key={index.toString()}>
                  <div className="aw-slider-bx fade-ani">
                    <div className="aw-img bg-white ">
                      <img
                        src={item?.image?.mediaItemUrl}
                        loading="lazy"
                        alt="award-image"
                      />
                    </div>
                    <div className="content pt-30">
                      <h6 className="text-19 text-black-500 uppercase ">
                        {item?.heading}
                      </h6>
                      <div className="btn-custom flex flex-wrap items-start justify-start space-x-8 mt-10 ">
                        <Link
                          to={item?.link?.url}
                          className="button btn-red-border"
                        >
                          {item?.link?.title}
                        </Link>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="swiper-arrow">
              <a
                className="button-prev cursor-pointer"
                onClick={handlePrevClickStay}
              >
                <img
                  src="/images/black-arrow.png"
                  loading="lazy"
                  className="scale-x-[-1]"
                  alt="Timeless Africa Safaris image"
                />
              </a>
              <a
                className="button-next cursor-pointer"
                onClick={handleNextClickStay}
              >
                <img
                  src="/images/black-arrow.png"
                  loading="lazy"
                  alt="Timeless Africa Safaris image"
                />
              </a>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default TimelessNews;
export const TimelessNewsFragment = graphql`
  fragment TimelessNewsFragment on WpPage_Pagecontent_PageContent_TimelessNews {
    id
    extraClass
    hideSection
    heading
    description
    data {
      heading
      image {
        altText
        mediaItemUrl
      }
      link {
        target
        title
        url
      }
    }
  }
`;
