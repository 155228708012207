import { graphql } from "gatsby";
import parse from "html-react-parser";
import React from "react";

const ColumnContent = ({ module }) => {
  return (
    <section className={`content-list bg-pattern lg:py-100 py-30${module?.extraClass ? " " + module.extraClass : ""}`}>
      <div className='container-fluid fade-ani'>
        {
          module?.heading && (
            <div className='title-black'>
              <h3>{module.heading}</h3>
            </div>
          )
        }
        {
          module?.data?.length > 0 && (
            <div className='content global-list grid grid-cols-2 gap-x-20 gap-y-5 ipad:grid-cols-2 mdscreen:grid-cols-1 mt-20 '>
              {
                module?.data?.map((item, index) => (
                  <div className="fade-ani" key={index.toString()}>{item?.description && parse(item?.description)}</div>
                ))
              }
            </div>
          )
        }
      </div>
    </section>
  )
}

export default ColumnContent;
export const ColumnContentFragment = graphql`
  fragment ColumnContentFragment on WpPage_Pagecontent_PageContent_ColumnContent {
    id
    extraClass
    hideSection
    heading
    data {
      description
    }
  }
`;