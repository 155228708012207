import { motion, useInView } from 'framer-motion';
import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const Faq = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const container2 = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.7,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  const defaultExpanded = module?.data
    ?.map((item, index) => (item?.defaultExpanded ? index : null))
    .filter((item) => item !== null);

  return (
    <section
      className={`faq lg:py-120 py-30${
        module?.extraClass ? ' ' + module.extraClass : ''
      }${
        module?.backgroundStyle === 'background with pattern'
          ? ' bg-pattern'
          : ''
      }`}
    >
      <div className="container-fluid-md fade-ani">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={isInView || hasPlayed ? 'show' : 'hidden'}
          variants={container}
          className="text-center"
        >
          {module?.heading && (
            <motion.div
              variants={item}
              className="title-black w-[830px] m-auto px-20 lgscreen:w-full"
            >
              <h3>{module.heading}</h3>
            </motion.div>
          )}
        </motion.div>
        <motion.div
          initial="hidden"
          animate={isInView || hasPlayed ? 'show' : 'hidden'}
          variants={container2}
        >
          <motion.div
            variants={item}
            className="accordian-section lg:px-120 px-60 smscreen2:px-0"
          >
            <Accordion allowZeroExpanded={true} preExpanded={defaultExpanded}>
              {module?.data &&
                module?.data?.length > 0 &&
                module.data.map((item, index) => (
                  <AccordionItem key={index} uuid={index}>
                    <AccordionItemHeading className="accordion">
                      <AccordionItemButton>{item.question}</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="fade-ani accordian-content content global-list pt-20">
                      {item?.answer && parse(item.answer)}
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
            </Accordion>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default Faq;
export const FaqFragment = graphql`
  fragment FaqFragment on WpTeam_Teamcontent_TeamContent_Faq {
    id
    hideSection
    extraClass
    heading
    backgroundStyle
    data {
      answer
      question
      defaultExpanded
    }
  }
`;
export const FaqPageFaqFragment = graphql`
  fragment FaqPageFaqFragment on WpPage_Pagecontent_PageContent_Faq {
    id
    hideSection
    extraClass
    heading
    backgroundStyle
    data {
      question
      answer
      defaultExpanded
    }
  }
`;
