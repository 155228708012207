import { motion, useInView } from 'framer-motion';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const ALL_CATEGORIES_ID = 'all-categories';

const BlogListing = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const container2 = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 1.1,
        staggerChildren: 0.2,
      },
    },
  };

  const aniItem = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  const [tabOption, setTabOption] = useState(ALL_CATEGORIES_ID);
  const pageSize = module?.pageSize || 8;

  const [numOfItemsToDisplay, setNumOfItemsToDisplay] = useState(pageSize);

  const onLoadMore = () => {
    setNumOfItemsToDisplay(numOfItemsToDisplay + pageSize);
  };

  const { postCategories, allPost } = useStaticQuery(graphql`
    query {
      postCategories: allWpCategory(filter: { slug: { ne: "private" } }) {
        nodes {
          id
          name
        }
      }
      allPost: allWpPost(
        filter: {
          id: { ne: "cG9zdDoxMTE5NQ==" }
          categories: { nodes: { elemMatch: { slug: { ne: "private" } } } }
        }
        sort: { fields: date, order: DESC }
      ) {
        nodes {
          id
          title
          uri
          excerpt
          link
          postContent {
            meta {
              author
            }
          }
          featuredImage {
            node {
              altText
              gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1024)
            }
          }
          categories {
            nodes {
              id
            }
          }
        }
      }
    }
  `);

  const featuredBlog = module?.featuredBlog;

  const getSelectedCategoryPosts = () => {
    return allPost.nodes
      .filter((item) => {
        if (tabOption === ALL_CATEGORIES_ID) {
          return true;
        }
        const postCategories = item?.categories?.nodes?.map((cat) => cat.id);
        if (
          postCategories?.length > 0 &&
          postCategories?.indexOf(tabOption) > -1
        ) {
          return true;
        }
        return false;
      })
      .filter((item) => item.id !== '11195');
  };

  const onShareClick = async (text, url) => {
    if (typeof navigator !== undefined) {
      if (navigator.share) {
        try {
          await navigator.share({
            title: 'Timeless Africa Safaris',
            text,
            url,
          });
          console.log('Data was shared successfully');
        } catch (err) {
          console.error('Share failed:', err.message);
        }
      } else {
        console.log('Share not supported');
      }
    }
  };

  const renderShareIcon = (blogItem) => {
    return (
      <Popup
        trigger={
          <button className="share flex items-center fade-ani">
            <img src="/images/share.png" loading="lazy" alt="Share" />
            <span className="uppercase text-12 text-black-200 pl-5">Share</span>
          </button>
        }
        position="top center"
      >
        <div className="sicon">
          <ul className="flex justify-center space-x-5">
            <li>
              <a
                href={`https://www.facebook.com/sharer.php?u=${blogItem?.link}`}
                target="_blank"
              >
                <img
                  src="/images/facebook-red.png"
                  loading="lazy"
                  alt="icon-facebook"
                />
              </a>
            </li>
            <li>
              <a
                href={`https://twitter.com/share?url=${blogItem?.link}&text=${blogItem?.title}`}
                target="_blank"
              >
                <img
                  src="/images/twitter-red.png"
                  loading="lazy"
                  alt="icon-twitter"
                />
              </a>
            </li>
          </ul>
        </div>
      </Popup>
    );
  };

  return (
    <>
      {featuredBlog && module?.hideSectionFeatured === 'no' && (
        <section className="featured-blog lg:py-50 py-30">
          <div className="container-fluid fade-ani">
            <div className="title-black">
              <h3>Featured Travel Story</h3>
            </div>
            <div className="featured-blog-bx mt-30 bg-pattern5">
              <div className="flex flex-wrap items-center">
                <div className="lg:w-5/12 w-full">
                  <GatsbyImage
                    image={getImage(featuredBlog?.featuredImage?.node)}
                    alt={featuredBlog?.featuredImage?.node?.altText}
                  />
                </div>
                <div className="lg:w-7/12 w-full">
                  <div className="fb-content  m-auto lgscreen:w-full px-80 lgscreen:px-20 lgscreen:py-30">
                    {renderShareIcon(featuredBlog)}
                    <div className="title-black mt-15 ">
                      <h5>{featuredBlog?.title}</h5>
                    </div>
                    {featuredBlog?.excerpt && (
                      <div className="content ">
                        {parse(featuredBlog?.excerpt)}
                      </div>
                    )}
                    <div className="btn-custom flex flex-wrap items-center space-x-8 mt-20 ">
                      <Link
                        to={featuredBlog?.uri}
                        className="button btn-red-border"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {allPost?.nodes?.length > 0 && (
        <section className="featured-blog-grid lg:pt-50 lg:pb-100 py-30">
          <motion.div
            ref={ref}
            initial="hidden"
            animate={isInView || hasPlayed ? 'show' : 'hidden'}
            variants={container}
            className="container-fluid text-center"
          >
            <motion.div variants={aniItem} className="title-black">
              <h3>{module.heading}</h3>
            </motion.div>
            <motion.div variants={aniItem} className="content ">
              <p>{parse(module.description)}</p>
            </motion.div>
            <motion.div
              variants={aniItem}
              className="tabs-content py-30 fade-ani"
            >
              <ul className="tabs justify-center">
                <li
                  onClick={() => {
                    setTabOption(ALL_CATEGORIES_ID);
                  }}
                  className={`tab-link cursor-pointer ${
                    tabOption == ALL_CATEGORIES_ID ? 'tab-current' : ''
                  }`}
                >
                  All Categories
                </li>
                {postCategories?.nodes?.length > 0 &&
                  postCategories?.nodes?.map((item, index) => (
                    <li
                      key={item.id}
                      onClick={() => {
                        setTabOption(item?.id);
                      }}
                      className={`tab-link cursor-pointer ${
                        tabOption == item.id ? 'tab-current' : ''
                      }`}
                    >
                      {item.name}
                    </li>
                  ))}
              </ul>
            </motion.div>
            <div className="tabs-container mt-40">
              <div class="tab-content global-list">
                <motion.div
                  initial="hidden"
                  animate={isInView || hasPlayed ? 'show' : 'hidden'}
                  variants={container2}
                  className="flex flex-wrap lg:mx-minus-15 mx-0 gap-y-8"
                >
                  {getSelectedCategoryPosts()
                    .slice(0, numOfItemsToDisplay)
                    .map((item) => (
                      <motion.div
                        variants={aniItem}
                        className="lg:w-6/12 w-full lg:px-15 px-0 fade-ani"
                        key={item.id}
                      >
                        <div className="fb-block bg-pattern5">
                          <div className="flex flex-wrap items-center">
                            <div className="lg:w-5/12 w-full lgscreen:h-[75vw] relative">
                              <GatsbyImage
                                image={getImage(item?.featuredImage?.node)}
                                alt={item?.featuredImage?.node?.altText}
                              />
                            </div>
                            <div className="lg:w-7/12 w-full">
                              <div className="fb-blog-grid-content lg:px-50 desktop3:px-20 lgscreen:py-20 text-left">
                                <div className="title-black mt-15 desktop3:mt-10 ">
                                  <h5>{item?.title}</h5>
                                </div>
                                {item?.excerpt && item?.excerpt !== '' && (
                                  <div className="content ">
                                    {parse(item?.excerpt)}
                                  </div>
                                )}
                                <div className="btn-custom flex flex-wrap items-center space-x-8 mt-20 ">
                                  <Link
                                    to={item?.uri}
                                    className="button btn-red-border"
                                  >
                                    Read More
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    ))}
                  {getSelectedCategoryPosts()?.length === 0 && (
                    <div>
                      <p className="text-center">
                        Blog list is empty in this category!
                      </p>
                    </div>
                  )}
                </motion.div>

                {getSelectedCategoryPosts()?.length > 0 &&
                  numOfItemsToDisplay < getSelectedCategoryPosts()?.length && (
                    <div className="btn-custom flex flex-wrap items-center justify-center space-x-8 lg:mt-100 mt-30 fade-ani">
                      <button
                        onClick={onLoadMore}
                        className="button btn-border"
                      >
                        <span>Load More Stories</span>
                        <img
                          src="/images/btn-red-bg.webp"
                          loading="lazy"
                          alt="button background image"
                        />
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </motion.div>
        </section>
      )}
    </>
  );
};

export default BlogListing;
export const BlogListingFragment = graphql`
  fragment BlogListingFragment on WpPage_Pagecontent_PageContent_BlogListing {
    id
    extraClass
    hideSection
    hideSectionFeatured
    heading
    description
    featuredBlog {
      ... on WpPost {
        id
        title
        excerpt
        uri
        link
        postContent {
          meta {
            author
          }
        }
        featuredImage {
          node {
            altText
            gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1024)
          }
        }
      }
    }
    pageSize
  }
`;
