import PageLayout from '../components/Layout/Page';
import Seo from '../components/Seo';
import Main from '../modules/Main';
import { graphql } from 'gatsby';
import React, { useEffect } from 'react';

const PageTemplate = (props) => {
  const modules = props?.data?.page?.pageContent?.pageContent || [];

  useEffect(() => {
    document.body.classList.remove('nav-menu-open');
    document.body.classList.remove('header-sticky');
    document.body.classList.remove('video-modal-open');
    document.body.classList.remove('blog-single-custom');
  }, []);

  return (
    <PageLayout>
      <Seo seo={props.data?.page.seo} />
      <Main
        modules={modules}
        parentId={props.data?.page?.parentId}
        location={props.location}
      />
    </PageLayout>
  );
};

export default PageTemplate;
export const PageById = graphql`
  query pageById($id: String) {
    page: wpPage(id: { eq: $id }) {
      id
      parentId
      ...seoPageFragment
      ...MainModulesFragment
    }
  }
`;
