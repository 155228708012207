import { motion, useInView } from 'framer-motion';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const customStylesBox = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'transparent',
    border: '0',
    borderRadius: '0',
    padding: '0',
    width: '100%',
    height: '100%',
    zindex: '999',
  },
};

const ImageWithModalContent = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  const [modalIsOpenBox, setIsOpenBox] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const openModalBox = (content) => {
    setModalContent(content);
    document.body.classList.add('video-modal-open');
    setIsOpenBox(true);
  };
  const closeModalBox = () => {
    document.body.classList.remove('video-modal-open');
    setIsOpenBox(false);
  };

  return (
    <>
      <section
        className={`zigzag lg:py-60 py-30 lg:mt-60 mt-0${
          module?.extraClass ? ' ' + module.extraClass : ''
        }`}
      >
        <div className="container-fluid">
          <div className="flex flex-wrap items-center">
            <div
              className={`lg:w-6/12 w-full h-[37vw] lgscreen:h-[75vw] ${
                module?.imagePosition === 'right' ? ' lg:order-2' : ''
              }`}
            >
              {module?.image && (
                <GatsbyImage
                  image={getImage(module.image)}
                  alt={module.image?.altText}
                  className="img"
                />
              )}
            </div>
            <div className="lg:w-6/12 w-full">
              <motion.div
                ref={ref}
                initial="hidden"
                animate={isInView || hasPlayed ? 'show' : 'hidden'}
                variants={container}
                className="zigzag-content lg:pt-0 pt-30 fade-ani"
              >
                {module?.heading && (
                  <motion.div variants={item} className="title-black ">
                    <h3>{module.heading}</h3>
                  </motion.div>
                )}
                {module?.description && (
                  <motion.div variants={item} className="content global-list ">
                    {parse(module.description)}
                  </motion.div>
                )}
                {module?.link && (
                  <motion.div
                    variants={item}
                    className="btn-custom flex flex-wrap items-start justify-start space-x-8 mt-30 "
                  >
                    {/* <Link to={module.link?.url} className="button btn-red cursor-pointer">{module.link?.title}</Link> */}
                    <button
                      onClick={() => openModalBox(module?.modalContent)}
                      className="flex justify-start items-center button btn-red-border"
                    >
                      {module?.link?.title}
                    </button>
                  </motion.div>
                )}
              </motion.div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        isOpen={modalIsOpenBox}
        onRequestClose={closeModalBox}
        style={customStylesBox}
      >
        <div className="zigzag-modal-popup bg-pattern2">
          <button
            onClick={closeModalBox}
            className="absolute right-30 top-30 w-50 h-50 bg-white rounded-999 inline-block z-99"
          >
            <img
              src="/images/modal-close.png"
              loading="lazy"
              className="m-auto w-20 h-20"
              alt="Close"
            />
          </button>
          <div className="flex flex-wrap items-center">
            <div className={`lg:w-5/12 w-full`}>
              <div className="img-slider">
                <Swiper
                  spaceBetween={0}
                  loop={false}
                  slidesPerView={1}
                  allowTouchMove={true}
                  grabCursor={true}
                  speed={1000}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="slider mdscreen:h-[90vw]"
                >
                  {modalContent?.images?.map((image, index) => (
                    <SwiperSlide key={index.toString()}>
                      <GatsbyImage
                        image={getImage(image)}
                        alt={image?.altText || ''}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            <div className="lg:w-7/12 w-full">
              <div className="zigzag-modal-popup-content lg:px-100 px-30 lg:pt-0 py-30">
                {modalContent?.preHeading && (
                  <span className="text-red-300 uppercase text-15 mb-15 inline-block w-full">
                    {modalContent?.preHeading}
                  </span>
                )}
                {modalContent?.heading && (
                  <div className="title-black fade-ani">
                    <h3>{modalContent?.heading}</h3>
                  </div>
                )}
                {modalContent?.description && (
                  <div className="content global-list ">
                    {parse(modalContent.description)}
                  </div>
                )}
                {modalContent?.button && (
                  <div className="btn-custom flex flex-wrap items-center space-x-8 mt-30 relative z-9">
                    <Link
                      to={modalContent.button?.url}
                      target={modalContent.button?.target}
                      className="button header-btn-red"
                    >
                      <span>{modalContent.button?.title}</span>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ImageWithModalContent;
export const ImageWithModalContentFragment = graphql`
  fragment ImageWithModalContentFragment on WpPage_Pagecontent_PageContent_ImageWithModalContent {
    id
    hideSection
    extraClass
    heading
    description
    imagePosition
    image {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
    }
    link {
      title
      target
      url
    }
    modalContent {
      heading
      description
      preHeading
      button {
        target
        title
        url
      }
      images {
        altText
        gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
      }
    }
  }
`;
