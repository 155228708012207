import { motion, useInView } from 'framer-motion';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';

const FounderMember = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  return (
    <section
      className={`team-bio bg-black-400 lg:py-90 py-30${
        module?.extraClass ? ' ' + module.extraClass : ''
      }`}
    >
      <div className="container-fluid max-w-[1200px] ml-auto mr-auto">
        <div className="flex flex-wrap items-center bg-pattern2">
          <div className="lg:w-5/12 w-full">
            <div className="img gatsby-image-wrapper">
              {module?.founder?.featuredImage?.node && (
                <GatsbyImage
                  image={getImage(module.founder.featuredImage.node)}
                  alt={module.founder.featuredImage.node.altText}
                  className="img lg:h-[350px]"
                />
              )}
            </div>
          </div>
          <div className="lg:w-7/12 w-full">
            <motion.div
              ref={ref}
              initial="hidden"
              animate={isInView || hasPlayed ? 'show' : 'hidden'}
              variants={container}
              className="team-bio-content max-w-[475px] m-auto lgscreen:max-w-full lgscreen:py-30 lgscreen:px-30 fade-ani"
            >
              {module?.founder?.teamContent?.meta?.position && (
                <motion.span
                  variants={item}
                  className="uppercase text-12 text-black-500 mb-15 inline-block"
                >
                  {module.founder.teamContent.meta.position}
                </motion.span>
              )}
              {module?.founder?.title && (
                <motion.div variants={item} className="title-black ">
                  <h3>{module.founder.title}</h3>
                </motion.div>
              )}
              {module?.founder?.content && (
                <motion.div variants={item} className="content ">
                  {parse(module.founder.content)}
                </motion.div>
              )}
              {module?.founder?.uri && (
                <motion.div
                  variants={item}
                  className="btn-custom flex flex-wrap items-center space-x-8 mt-30 relative z-9 "
                >
                  <Link
                    to={module.founder.uri}
                    className="button btn-red-border"
                  >
                    Read More
                  </Link>
                </motion.div>
              )}
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FounderMember;
export const FounderMemberFragment = graphql`
  fragment FounderMemberFragment on WpPage_Pagecontent_PageContent_FounderMember {
    id
    hideSection
    extraClass
    founder {
      ... on WpTeam {
        id
        title
        uri
        content
        teamContent {
          meta {
            position
          }
        }
        featuredImage {
          node {
            altText
            gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
          }
        }
      }
    }
  }
`;
