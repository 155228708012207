import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import Modal from "react-modal";
import ReactPlayer from "react-player";

const customStylesVideo = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'transparent',
    border: '0',
    borderRadius: '0',
    padding: '0',
    width: '100%',
    height: '100%',
    zindex: '999',
  },
};

const FullVideo = ({ module }) => {
  const [modalIsOpenVideo, setIsOpenVideo] = React.useState(false);

  function openModalVideo() {
    document.body.classList.add("video-modal-open");
    setIsOpenVideo(true);
  }
  function closeModalVideo() {
    document.body.classList.remove("video-modal-open");
    setIsOpenVideo(false);
  }

  return (
    <>
      <section className={`bg-img-content relative${module?.extraClass ? " " + module.extraClass : ""}`}>
        {
          module?.backgroundImage && (
            <GatsbyImage 
              image={getImage(module.backgroundImage)}
              alt={module.backgroundImage?.altText}
            />
          )
        }
        {
          module?.video && (
            <div className='banner-video py-10'>
              <button onClick={() => { openModalVideo(); }} className='flex justify-start items-center'>
                <div className='video-icon w-20 relative top-[-2px]'>
                  <img src="/images/play.png" loading="lazy" className='m-auto' alt="Play" />
                </div>
              </button>
            </div>
          )
        }
      </section>
      <Modal
        isOpen={modalIsOpenVideo}
        onRequestClose={closeModalVideo}
        style={customStylesVideo}
      >
        <div className="video-modal-popup h-full">
          <button onClick={closeModalVideo} className="absolute right-30 top-30 w-50 h-50 bg-white rounded-999 z-999 inline-block">
            <img src="/images/modal-close.png" loading="lazy" className='m-auto w-20 h-20' alt="Close" />
          </button>
          <div className="modal-video h-full">
            {
              module?.video && module?.video !== "" ? (
                <ReactPlayer
                  className="modal-banner-video"
                  loop={true}
                  playing={true}
                  controls={true}
                  muted={true}
                  url={module.video}
                />
              ) : (
                <span>
                  Video Not Found!
                </span>
              )
            }
          </div>
        </div>
      </Modal>
    </>
  )
}

export default FullVideo;
export const FullVideoFragment = graphql`
  fragment FullVideoFragment on WpPage_Pagecontent_PageContent_FullVideo {
    id
    extraClass
    hideSection
    video
    backgroundImage {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 2400)
    }
    fallbackImage {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 2400)
    }
  }
`;