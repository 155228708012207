import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion, useInView } from 'framer-motion';
import { graphql, Link, useStaticQuery } from 'gatsby';
import parse from 'html-react-parser';
import 'rc-tooltip/assets/bootstrap_white.css';
import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { Autoplay, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { v4 as uuidv4 } from 'uuid';

const BestTime = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [modalIsOpenBox, setIsOpenBox] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabClick = (index) => {
    setActiveTabIndex(index); // Update active tab index on click
  };

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.2,
        staggerChildren: 0.05,
      },
    },
  };

  const container2 = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.9,
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  const aniItem = {
    hidden: { opacity: 0, y: 10 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  const openModalBox = (currentDestination) => {
    setModalContent(currentDestination);
    setIsOpenBox(true);
    document.body.classList.add('best-modal-open');
  };

  const closeModalBox = () => {
    setIsOpenBox(false);
    document.body.classList.remove('best-modal-open');
  };

  const handleNextClickStay = () => {
    const swiper = document.querySelector('.trip-slider .swiper').swiper;
    swiper.slideNext();
  };

  const handlePrevClickStay = () => {
    const swiper = document.querySelector('.trip-slider .swiper').swiper;
    swiper.slidePrev();
  };

  useEffect(() => {
    imgCdn();
  }, []);

  const imgCdn = (imgData, width, height) => {
    const img = `https://wsrv.nl/?url=${imgData}&w=${width}&h=${height}&fit=cover&output=webp`;
    return img;
  };

  const query = useStaticQuery(graphql`
    query {
      allWpBestTime(sort: { fields: [date], order: ASC }) {
        nodes {
          title
          BestMonths {
            fieldGroupName
            destinations {
              ... on WpDestination {
                id
                title
                excerpt
                uri
                featuredImage {
                  node {
                    mediaItemUrl
                  }
                }
                BestTimeToGo {
                  offPeakSeason
                  peakSeason
                  experiences
                }
              }
            }
            experiences {
              ... on WpExperience {
                id
                title
                excerpt
                featuredImage {
                  node {
                    mediaItemUrl
                  }
                }
                destinationsCategory {
                  nodes {
                    name
                  }
                }
              }
            }
            trips {
              ... on WpTrip {
                id
                title
                featuredImage {
                  node {
                    mediaItemUrl
                  }
                }
                tripContent {
                  meta {
                    totalNights
                    price
                    curatedFor
                    location {
                      ... on WpDestination {
                        id
                        title
                      }
                    }
                    curatedBy {
                      ... on WpTeam {
                        id
                        title
                        uri
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const data = query.allWpBestTime.nodes;
  const allTitles = query?.allWpBestTime?.nodes?.map((node) => node.title);

  return (
    <>
      <section className="month-tabs">
        <div className="container-fluid-xl bg-pattern py-30">
          <motion.div className="month-info flex flex-wrap justify-between lgscreen:gap-x-5 mdscreen:gap-x-[0.75rem] lgscreen:justify-center lgscreen:gap-y-5 mdscreen:gap-y-[0.75rem] fade-ani">
            {allTitles.map((item, index) => (
              <motion.div
                variants={aniItem}
                key={index.toString()}
                className={`circle-grid cursor-pointer ${
                  activeTabIndex === index ? 'active' : ''
                }`}
                onClick={() => handleTabClick(index)}
              >
                <span>{item.slice(0, 3)}</span>
              </motion.div>
            ))}
          </motion.div>
        </div>

        {allTitles.map((item, index) => (
          <>
            <div
              key={index.toString()}
              className={`pt-100 ${activeTabIndex === index ? '' : 'hidden'}`}
            >
              <motion.div className="section-title text-left fade-ani px-[150px] smscreen:px-20">
                <motion.div className="title-black">
                  <h3>Best Destinations to travel to in {item}</h3>
                </motion.div>
              </motion.div>

              <div className="cards-container px-[150px] pt-50 smscreen:px-20 pb-30">
                <div className="">
                  <div className="card">
                    <div className="card-wrapper grid grid-cols-1 lg:grid-cols-3 gap-8">
                      {data[index].BestMonths.destinations.map(
                        (destination, destinationIndex) => (
                          <div
                            key={destinationIndex.toString()}
                            className="db-bx bg-white"
                          >
                            <img
                              className="hover:cursor-grab !min-h-[20vw] !lgscreen:min-h-[20vw] !mdscreen:min-h-[30vw] object-cover"
                              src={destination.featuredImage.node.mediaItemUrl}
                              alt="My Image"
                            />
                            <div className="db-content lg:py-30 py-15 lg:px-0 px-10 hover:cursor-pointer">
                              <div className="content">
                                <h6 className="uppercase text-19 lgscreen:text-16 mdscreen:text-16 mdscreen:leading-[20px] text-black-500">
                                  {destination.title}
                                </h6>
                                <div className="pt-5 font-300">
                                  {parse(destination.excerpt)}
                                </div>
                              </div>
                              <div className="btn-custom flex flex-wrap items-center space-x-8 mt-15">
                                <a
                                  href="#"
                                  className="button btn-red-border"
                                  onClick={() => {
                                    const currentDestination = destination;
                                    openModalBox(currentDestination);
                                  }}
                                >
                                  Learn Why
                                </a>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <section className="bx-img-content bg-pattern py-60 best-experiences">
                <div className="container-fluid-xl">
                  <motion.div className="section-title text-center fade-ani">
                    <motion.div className="title-black">
                      <h3>Best Experiences in {item}</h3>
                    </motion.div>
                  </motion.div>
                  <motion.div className="bx-img-content-main grid gap-y-14 mdscreen:gap-y-7 lg:mt-60 mt-30">
                    {data[index].BestMonths.experiences.map(
                      (experience, expIndex) => (
                        <motion.div
                          key={expIndex}
                          className="bx-img-content-grid fade-ani"
                        >
                          <div className="flex flex-wrap">
                            <div className="lg:w-5/12 w-full">
                              <img
                                src={experience.featuredImage.node.mediaItemUrl}
                                alt={
                                  experience.title
                                    ? experience.title
                                    : 'Timeless Africa Safaris image'
                                }
                                className="w-full smscreen2:h-[300px] h-[380px] "
                              />
                            </div>
                            <div className="lg:w-7/12 w-full">
                              <div className="trip-content bg-white lg:px-80 px-15 h-full flex flex-col justify-center lg:p-0 p-30">
                                <div className="lg:w-[400px] !w-full">
                                  <div className="title-black mt-10 mb-10">
                                    <h5>{experience.title}</h5>
                                  </div>
                                  <div className="content flex mt-5">
                                    <FontAwesomeIcon
                                      icon={faMapMarkerAlt}
                                      className="text-red-300 fa-map-marker-alt mt-[6px] pr-[8px]"
                                    />
                                    <div className="inline">
                                      {experience.destinationsCategory.nodes.map(
                                        (node, nodeIndex) => (
                                          <span
                                            key={nodeIndex}
                                            className="dest-item font-800 text-red-300"
                                          >
                                            {node.name}
                                          </span>
                                        )
                                      )}
                                    </div>
                                  </div>
                                  <div className="pt-10 w-[90%] font-300">
                                    {parse(experience.excerpt)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </motion.div>
                      )
                    )}
                  </motion.div>
                </div>
              </section>

              <section
                className={`trip-slider lg:py-60 py-30 ${
                  module?.backgroundStyle === 'background with pattern'
                    ? ' bg-pattern bg-pattern-spacing'
                    : ''
                }${module?.extraClass ? ' ' + module.extraClass : ''}`}
              >
                <div className="md:w-8/12 w-full m-auto">
                  <motion.div
                    ref={ref}
                    initial="show"
                    animate={isInView || hasPlayed ? 'show' : 'hidden'}
                    variants={container}
                    className="trip-slider-top text-center px-20"
                  >
                    <motion.div variants={item} className="title-black">
                      <h3>POPULAR TRIP INSPIRATION</h3>
                    </motion.div>
                    <motion.div
                      variants={item}
                      className="content w-[474px] smscreen2:w-full smscreen2:px-20 m-auto "
                    >
                      Discover our expertly-curated Trip Inspiration
                    </motion.div>
                  </motion.div>
                </div>
                <div
                  id="trip-slider-row"
                  className="trip-slider-row trip-drag-section trip-slider-grid lg:mt-50 mt-25 fade-ani relative overflow-hidden"
                >
                  <Swiper
                    className="h-full"
                    loop={true}
                    lazy={{
                      loadPrevNext: true,
                    }}
                    slidesPerView={2.3}
                    spaceBetween={20}
                    centeredSlides={true}
                    allowTouchMove={true}
                    speed={1000}
                    pagination={{
                      el: '.trip-slider-pagination',
                      type: 'progressbar',
                    }}
                    navigation={false}
                    modules={[Autoplay, Pagination, Scrollbar]}
                    breakpoints={{
                      100: {
                        slidesPerView: 1.3,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 2.5,
                        spaceBetween: 20,
                      },
                      1024: {
                        slidesPerView: 2.3,
                        spaceBetween: 20,
                      },
                    }}
                  >
                    {data[index].BestMonths.trips.map((item) => (
                      <SwiperSlide key={uuidv4()}>
                        <Link to={item?.uri} className="hover:cursor-grab">
                          {item?.featuredImage?.node && (
                            <img
                              src={imgCdn(
                                item?.featuredImage?.node?.mediaItemUrl,
                                615,
                                410
                              )}
                              loading="lazy"
                              alt={
                                item?.featuredImage?.node?.altText
                                  ? item?.featuredImage?.node?.altText
                                  : 'Timeless Africa Safaris image'
                              }
                              className="w-full"
                              width="615"
                              height="410"
                            />
                          )}
                        </Link>
                        <div className="trip-slider-content pt-30 lg:px-10 px-10">
                          <div className="trip-top-info flex items-center justify-between mb-15">
                            <h5>
                              {item.tripContent?.meta?.totalNights}
                              <span>{item.tripContent?.meta?.price}</span>
                            </h5>
                            <h6>
                              Originally curated for
                              <span className="dest-item">
                                {item.tripContent?.meta?.curatedFor}
                              </span>
                            </h6>
                          </div>
                          <div className="trip-slider-content-inner flex flex-wrap mdscreen:flex-col">
                            <div className="w-full mdscreen:order-2 pr-30 lgscreen:pr-15">
                              <div className="title-black ">
                                <Link to={item?.uri}>
                                  <h5>{item.title}</h5>
                                </Link>
                              </div>
                              <div className="content flex mt-5 ">
                                <FontAwesomeIcon
                                  icon={faMapMarkerAlt}
                                  className="text-red-300 fa-map-marker-alt"
                                />
                                <div className="inline">
                                  {item.tripContent?.meta?.location?.map(
                                    (item, i) => (
                                      <span className="dest-item" key={i}>
                                        {parse(item.title)}
                                      </span>
                                    )
                                  )}
                                </div>
                              </div>
                              <div className="btn-custom trip-btn-ignore ignore-btn flex flex-wrap items-start justify-start space-x-8 mt-20 ">
                                <div className="ignore-btn">
                                  <Link
                                    to={item?.uri}
                                    className="button btn-red-border"
                                  >
                                    Explore Trip Inspiration
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div className="swiper-arrow swiper-arrow-red">
                    <a
                      className="button-prev cursor-pointer absolute top-[30%] left-[18vw] mdscreen:left-[55px] translate-y-minus_50 lgscreen:top-[17.5%] z-9"
                      onClick={handlePrevClickStay}
                    >
                      <img
                        src="/images/white-arrow.svg"
                        loading="lazy"
                        className="scale-x-[-1]"
                        alt="white arrow"
                      />
                    </a>
                    <a
                      className="button-next cursor-pointer absolute top-[30%] lgscreen:top-[17.5%] right-[18vw]  mdscreen:right-[55px] translate-y-minus_50 z-9"
                      onClick={handleNextClickStay}
                    >
                      <img
                        src="/images/white-arrow.svg"
                        loading="lazy"
                        alt="white arrow"
                      />
                    </a>
                  </div>
                  <div className="trip-slider__bottom-bar mt-10 mdscreen:mt-0 px-70 lgscreen:px-30">
                    <div className="trip-slider__scrollbar relative flex-1 overflow-hidden left-0 bottom-0 h-[2px]">
                      <div className="slider-progressbar trip-slider-pagination swiper-pagination"></div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <Modal
              isOpen={modalIsOpenBox}
              onRequestClose={closeModalBox}
              className="w-full h-full"
            >
              <div className=" bg-white w-full h-auto">
                <button
                  onClick={closeModalBox}
                  className="absolute right-30 top-30 w-50 h-50 bg-white rounded-999 inline-block z-999"
                >
                  <img
                    src="/images/modal-close.png"
                    loading="lazy"
                    className="m-auto w-20 h-20"
                    alt="Close"
                  />
                </button>
                <div className="flex flex-wrap items-center h-full">
                  <div className="lg:w-5/12 w-full h-full mdscreen:h-[300px] relative">
                    <div className="img h-full">
                      <img
                        src={modalContent?.featuredImage.node.mediaItemUrl}
                        className="w-full h-screen lgscreen:h-full object-cover"
                        alt="Destination Image"
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="lg:w-7/12 w-full h-full mdscreen:h-[calc(100vh-300px)] pt-30 ">
                    <div className="px-100 laptop:px-50 lgscreen:px-20 lgscreen:py-30 h-full overflow-auto">
                      <div className="pb-5 mb-5 pt-50 lgscreen:mb-15 text-15  text-red-300">
                        <span className="font-800 uppercase ">
                          Destinations
                        </span>
                      </div>
                      <h3>{modalContent?.title}</h3>
                      <div className="content">
                        <div className="table-wrapper__inner pt-20">
                          <table className="w-full border-collapse mdscreen:hidden">
                            <tbody>
                              <tr class="">
                                <th
                                  className="text-left border-1 border-black-200 p-15 text-red-300 w-4/12"
                                  scope="col"
                                >
                                  Peak Season
                                </th>
                                <th
                                  className="text-left border-1 border-black-200 p-15 text-red-300 w-4/12"
                                  scope="col"
                                >
                                  Off-peak Season
                                </th>
                                <th
                                  className="text-left border-1 border-black-200 p-15 text-red-300 w-4/12"
                                  scope="col"
                                >
                                  Experiences
                                </th>
                              </tr>
                              <tr>
                                <td class="border-1 border-black-200 align-top p-15 text-left best-list">
                                  {modalContent?.BestTimeToGo.peakSeason &&
                                    parse(modalContent.BestTimeToGo.peakSeason)}
                                </td>
                                <td className="border-1 border-black-200 align-top p-15 text-left best-list">
                                  {modalContent?.BestTimeToGo.offPeakSeason &&
                                    parse(
                                      modalContent.BestTimeToGo.offPeakSeason
                                    )}
                                </td>
                                <td className="border-1 border-black-200 p-15 align-top text-left best-list">
                                  {modalContent?.BestTimeToGo.experiences &&
                                    parse(
                                      modalContent.BestTimeToGo.experiences
                                    )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table className="w-full border-collapse md:hidden">
                            <tbody>
                              <tr className="">
                                <th
                                  className="text-left border-1 border-black-200 p-15 text-red-300"
                                  scope="col"
                                >
                                  Peak Season
                                </th>
                              </tr>
                              <tr>
                                <td className="border-1 border-black-200 align-top p-15 text-left best-list">
                                  {modalContent?.BestTimeToGo.peakSeason &&
                                    parse(modalContent.BestTimeToGo.peakSeason)}
                                </td>
                              </tr>
                              <tr className="">
                                <th
                                  className="text-left border-1 border-black-200 p-15 text-red-300"
                                  scope="col"
                                >
                                  Off-peak Season
                                </th>
                              </tr>
                              <tr>
                                <td className="border-1 border-black-200 align-top p-15 text-left best-list">
                                  {modalContent?.BestTimeToGo.offPeakSeason &&
                                    parse(
                                      modalContent.BestTimeToGo.offPeakSeason
                                    )}
                                </td>
                              </tr>
                              <tr className="">
                                <th
                                  className="text-left border-1 border-black-200 p-15 text-red-300"
                                  scope="col"
                                >
                                  Experiences
                                </th>
                              </tr>
                              <tr>
                                <td className="border-1 border-black-200 p-15 align-top text-left best-list">
                                  {modalContent?.BestTimeToGo.experiences &&
                                    parse(
                                      modalContent.BestTimeToGo.experiences
                                    )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="flex flex-wrap items-center space-x-8 mt-30 md:pb-40">
                        <div className="btn-custom relative z-9 fade-ani">
                          <Link
                            to="/guest-enquiry/"
                            className="button header-btn-red cursor-pointer"
                          >
                            Let's Start Planning
                          </Link>
                        </div>

                        <Link
                          to={modalContent?.uri}
                          className="button btn-red-border py-15"
                        >
                          Explore Destination
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </>
        ))}
      </section>
    </>
  );
};

export default BestTime;
