import ImageOpt from './ImageOpt';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion, useInView } from 'framer-motion';
import { Link, graphql, useStaticQuery } from 'gatsby';
import parse from 'html-react-parser';
import 'rc-tooltip/assets/bootstrap_white.css';
import React, { useEffect, useRef, useState } from 'react';

const TripInspirationListing = ({ module }) => {
  const ref = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const isInView = useInView(ref2);
  const isInView2 = useInView(ref3);

  const [hasPlayed, setHasPlayed] = useState(false);
  const [hasPlayed2, setHasPlayed2] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  const container2 = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  useEffect(() => {
    if (isInView2 && !hasPlayed2) {
      setHasPlayed2(true);
    }
  }, [isInView2, hasPlayed2, setHasPlayed2]);

  const pageSize = module?.pageSize || 8;
  const [itemsToDisplay, setItemsToDisplay] = useState(pageSize);

  const [destinationFilter, setDestinationFilter] = useState('');
  const [travelStyleFilter, setTravelStyleFilder] = useState('');
  const [tripDurationFilter, setTripDurationFilter] = useState('');
  let paramsDestination = '';
  let paramsTravel = '';

  if (typeof window !== 'undefined') {
    paramsDestination = new URL(window.location.href).searchParams.get(
      'destination'
    );
    paramsTravel = new URL(window.location.href).searchParams.get('travel');
  }

  useEffect(() => {
    setDestinationFilter(paramsDestination);
    if (paramsDestination !== null || paramsTravel !== null) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [paramsDestination]);

  useEffect(() => {
    setTravelStyleFilder(paramsTravel);
  }, [paramsTravel]);

  const onLoadMore = () => {
    setItemsToDisplay(itemsToDisplay + pageSize);
  };

  const makeBold = (item) => {
    let keyword = item;
    if (
      item === 'South Africa' ||
      item === 'Namibia' ||
      item === 'Botswana' ||
      item === 'Mozambique' ||
      item === 'Madagascar' ||
      item === 'Mauritius' ||
      item === 'Seychelles' ||
      item === 'Uganda' ||
      item === 'Tanzania' ||
      item === 'Rwanda' ||
      item === 'Kenya' ||
      item === 'Republic of Congo' ||
      item === 'Zambia' ||
      item === 'Zimbabwe'
    ) {
      const re = new RegExp(keyword, 'g');
      const boldItem = item
        .replace(
          re,
          "<span className='dest-item font-800 text-red-300'>" +
            keyword +
            '</span>'
        )
        .toString();

      return boldItem;
    } else {
      const re = new RegExp(keyword, 'g');
      const normalItem = item
        .replace(re, "<span className='dest-item'>" + keyword + '</span>')
        .toString();
      return normalItem;
    }
  };

  const { destinations, travels, trips } = useStaticQuery(graphql`
    query {
      destinations: allWpDestination(
        filter: { wpParent: { node: { id: { eq: null } } } }
        sort: { fields: date, order: DESC }
      ) {
        nodes {
          id
          title
          wpChildren {
            nodes {
              id
              slug
              ... on WpDestination {
                id
                title
              }
            }
          }
        }
      }
      travels: allWpTravel {
        nodes {
          id
          title
        }
      }
      trips: allWpTrip {
        nodes {
          id
          uri
          title
          featuredImage {
            node {
              mediaItemUrl
              gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 500)
              altText
            }
          }
          tripContent {
            meta {
              totalNights
              price
              curatedFor
              location {
                ... on WpDestination {
                  id
                  title
                  wpParent {
                    node {
                      ... on WpDestination {
                        id
                        title
                      }
                    }
                  }
                }
              }
              travelStyle {
                ... on WpTravel {
                  id
                  title
                }
              }
              features {
                icon
                name
                featureIcon {
                  altText
                  mediaItemUrl
                }
              }
              curatedBy {
                ... on WpTeam {
                  id
                  title
                  uri
                }
              }
            }
          }
        }
      }
    }
  `);

  const destinationValues = (
    destinations.nodes.flatMap(({ wpChildren }) =>
      wpChildren.nodes.map((item) => ({ id: item.id, title: item.title }))
    ) || []
  ).sort((a, b) => a.title.localeCompare(b.title));
  const [tripsToDisplay, setTripsToDisplay] = useState(trips?.nodes || []);
  let tripDurations = trips.nodes
    .map((trip) => trip?.tripContent?.meta?.totalNights || '')
    .filter((item) => item !== '');
  tripDurations = tripDurations.filter(
    (item, pos) => tripDurations.indexOf(item) === pos
  );

  const onDestinationFilterChange = (newDestination) => {
    setDestinationFilter(newDestination);
  };

  const onTravelStyleFilterChange = (newTravelStyle) => {
    setTravelStyleFilder(newTravelStyle);
  };

  function shuffleArray(array) {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  }

  useEffect(() => {
    if (destinationFilter || travelStyleFilter) {
      const filteredTrips = trips?.nodes.filter((item) => {
        const locations =
          item?.tripContent?.meta?.location?.length > 0
            ? item?.tripContent?.meta?.location.map((location) => location)
            : [];
        const travelStyles =
          item?.tripContent?.meta?.travelStyle?.length > 0
            ? item?.tripContent?.meta?.travelStyle.map(
                (travelStyle) => travelStyle?.title
              )
            : [];
        if (
          destinationFilter !== null &&
          destinationFilter !== '' &&
          (travelStyleFilter === '' || travelStyleFilter === null)
        ) {
          var status = false;
          locations.map((locations) => {
            if (
              locations?.title === destinationFilter ||
              locations?.wpParent?.node?.title === destinationFilter
            ) {
              status = true;
            }
          });
          return status;
        } else if (
          travelStyleFilter !== null &&
          travelStyleFilter !== '' &&
          (destinationFilter === '' || destinationFilter === null)
        ) {
          if (travelStyles?.indexOf(travelStyleFilter) > -1) {
            return true;
          }
        } else if (
          destinationFilter !== null &&
          destinationFilter !== '' &&
          travelStyleFilter !== '' &&
          travelStyleFilter !== null
        ) {
          var status = false;
          locations.map((locations) => {
            if (
              (locations?.title === destinationFilter ||
                locations?.wpParent?.node?.title === destinationFilter) &&
              travelStyles?.indexOf(travelStyleFilter) > -1
            ) {
              status = true;
            }
          });
          return status;
        }
        return false;
      });

      const shuffledTrips = shuffleArray(filteredTrips);

      setTripsToDisplay(shuffledTrips);
    } else {
      const shuffledTrips = shuffleArray(trips?.nodes);
      setTripsToDisplay(shuffledTrips);
    }
  }, [destinationFilter, travelStyleFilter, tripDurationFilter]);

  return (
    <section
      ref={ref}
      id="tripListing"
      className={`two-img-grid lg:py-120 py-60${
        module?.extraClass ? ' ' + module.extraClass : ''
      }`}
    >
      <div className="container-fluid">
        <motion.div
          ref={ref2}
          initial="hidden"
          animate={isInView || hasPlayed ? 'show' : 'hidden'}
          variants={container}
          className="section-title fade-ani"
        >
          {module?.heading && (
            <motion.div variants={item} className="title-black">
              <h3>{module.heading}</h3>
            </motion.div>
          )}
          {module?.description && (
            <motion.div variants={item} className="content ">
              {parse(module.description)}
            </motion.div>
          )}
        </motion.div>

        <div className="img-filter lg:mt-50 mt-25 ">
          <div className="lg:w-6/12 w-full">
            <motion.div
              initial="hidden"
              animate={isInView2 || hasPlayed2 ? 'show' : 'hidden'}
              variants={container2}
              className="flex flex-wrap lg:mx-minus-20 mx-0 fade-ani"
            >
              {destinations?.nodes?.length > 0 && (
                <div className="lg:w-4/12 w-full lg:px-20 px-0">
                  <div className="from-group">
                    <select
                      value={
                        destinationFilter === null ? '' : destinationFilter
                      }
                      onChange={(e) =>
                        onDestinationFilterChange(e.target.value)
                      }
                    >
                      <option value="">All Destinations</option>

                      {destinationValues.map((destination) => (
                        <option key={destination.id} value={destination.title}>
                          {destination.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              {travels?.nodes?.length > 0 && (
                <div className="lg:w-4/12 w-full lg:px-20 px-0">
                  <div className="from-group">
                    <select
                      value={
                        travelStyleFilter === null ? '' : travelStyleFilter
                      }
                      onChange={(e) =>
                        onTravelStyleFilterChange(e.target.value)
                      }
                    >
                      <option value="">All Travel Styles</option>

                      {travels.nodes.map((travelStyle) => (
                        <option key={travelStyle.id} value={travelStyle.title}>
                          {travelStyle.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </motion.div>
          </div>
        </div>
        <div className="img-grid ">
          {tripsToDisplay?.length > 0 ? (
            <motion.div
              ref={ref3}
              initial="hidden"
              animate={isInView2 || hasPlayed2 ? 'show' : 'hidden'}
              variants={container}
              className="flex flex-wrap lg:gap-y-20 gap-y-10 lg:mx-minus-15 mx-0 lg:mt-50 mt-25"
            >
              {tripsToDisplay.slice(0, itemsToDisplay).map((trip, index) => (
                <motion.div
                  variants={item}
                  key={index}
                  className="lg:w-6/12 w-full lg:px-15 px-0"
                >
                  <div className="img-grid-bx fade-ani">
                    <Link to={trip.uri}>
                      <ImageOpt
                        imgData={trip?.featuredImage?.node.mediaItemUrl}
                        imgAlt={trip?.featuredImage?.node?.altText}
                        imgClass="w-full"
                        width={635}
                        height={356}
                      />
                    </Link>
                    <div className="img-grid-content pt-30">
                      <div className="top-info flex items-center justify-between mb-10 fade-ani">
                        <h5>
                          {trip?.tripContent?.meta?.totalNights}
                          <span>{trip?.tripContent?.meta?.price}</span>
                        </h5>
                        <h6>
                          Originally curated for
                          <span>{trip?.tripContent?.meta?.curatedFor}</span>
                        </h6>
                      </div>
                      <div className="img-grid-content-inner flex flex-wrap mdscreen:flex-col">
                        <div className="md:w-10/12 w-full lgscreen:w-[75%] mdscreen:w-full mdscreen:order-2">
                          <div className="title-black">
                            <Link to={trip.uri}>
                              <h5>{trip.title}</h5>
                            </Link>
                          </div>
                          <div className="content flex mt-5">
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                              className="text-red-300 fa-map-marker-alt mt-[2px] pr-[8px]"
                            />
                            <p>
                              {trip.tripContent?.meta?.location?.map((item) =>
                                parse(makeBold(item.title))
                              )}
                            </p>
                          </div>
                          <div className="btn-custom flex flex-wrap items-start justify-start space-x-8 mt-20 ">
                            <Link
                              to={trip.uri}
                              className="button btn-red-border"
                            >
                              Explore This Trip
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          ) : (
            <div className="pt-50">
              <p className="text-30">
                No results were found to match your requirements, have a look at
                some alternative ideas below or get in touch with us as we would
                love to create something unique for you.
              </p>
            </div>
          )}

          {itemsToDisplay < tripsToDisplay?.length && (
            <div className="btn-custom flex flex-wrap items-center justify-center space-x-8 lg:mt-70 mt-30 fade-ani">
              <button className="button btn-border" onClick={onLoadMore}>
                <span>Load More Trip Inspiration</span>
                <img
                  src="/images/btn-red-bg.webp"
                  loading="lazy"
                  alt="Timeless Africa Safaris image"
                />
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default TripInspirationListing;
export const TripInspirationListingFragment = graphql`
  fragment TripInspirationListingFragment on WpPage_Pagecontent_PageContent_TripInspirationListing {
    id
    extraClass
    hideSection
    heading
    description
  }
`;
