import { motion, useInView } from 'framer-motion';
import { Link, graphql } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const OurPartners = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  const container2 = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.8,
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  const handleNextClickStay = () => {
    const swiper = document.querySelector('.our-partners .swiper').swiper;
    swiper.slideNext();
  };
  const handlePrevClickStay = () => {
    const swiper = document.querySelector('.our-partners .swiper').swiper;
    swiper.slidePrev();
  };

  return (
    <section
      className={`our-partners pt-60 bg-pattern${
        module?.extraClass ? ' ' + module.extraClass : ''
      }`}
    >
      <div className="container-fluid-xl fade-ani">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={isInView || hasPlayed ? 'show' : 'hidden'}
          variants={container}
          className="section-title text-center"
        >
          {module?.heading && (
            <motion.div variants={item} className="title-black">
              <h3>{module.heading}</h3>
            </motion.div>
          )}
          {module?.subHeading && (
            <motion.div variants={item} className="content ">
              <p>{module.subHeading}</p>
            </motion.div>
          )}
        </motion.div>
        <motion.div
          initial="hidden"
          animate={isInView || hasPlayed ? 'show' : 'hidden'}
          variants={container2}
          className="our-partners-slider relative "
        >
          {module?.gallery?.length > 0 && (
            <Swiper
              className="h-full"
              spaceBetween={10}
              loop={true}
              loopedSlides={module?.gallery?.length - 1}
              slidesPerView={2}
              slidesPerGroup={2}
              allowTouchMove={true}
              grabCursor={true}
              speed={1000}
              navigation={false}
              modules={[Pagination, Navigation]}
              breakpoints={{
                100: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 6,
                  spaceBetween: 10,
                },
                1200: {
                  slidesPerView: 8,
                  spaceBetween: 10,
                },
                1365: {
                  slidesPerView: 6,
                  spaceBetween: 10,
                },
                1440: {
                  slidesPerView: 8,
                  spaceBetween: 10,
                },
              }}
            >
              {module.gallery.map((image, i) => (
                <SwiperSlide key={i}>
                  <div className="img">
                    <img
                      src={image?.mediaItemUrl}
                      loading="lazy"
                      alt={image?.altText}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
          <div className="swiper-arrow">
            <a
              className="button-prev cursor-pointer"
              onClick={handlePrevClickStay}
            >
              <img
                src="/images/black-arrow.png"
                loading="lazy"
                className="scale-x-[-1]"
                alt="Timeless Africa Safaris image"
              />
            </a>
            <a
              className="button-next cursor-pointer"
              onClick={handleNextClickStay}
            >
              <img
                src="/images/black-arrow.png"
                loading="lazy"
                alt="Timeless Africa Safaris image"
              />
            </a>
          </div>
        </motion.div>
        {module?.button?.title && (
          <div className="btn-custom flex flex-wrap items-center justify-center space-x-8 lg:mt-50 mt-25">
            <Link to={module.button.url} className="button btn-border">
              <span>{module.button.title}</span>
              <img
                src="/images/btn-red-bg.webp"
                loading="lazy"
                alt="Timeless Africa Safaris image"
              />
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default OurPartners;
export const OurPartnersFragment = graphql`
  fragment OurPartnersFragment on WpPage_Pagecontent_PageContent_OurPartners {
    id
    backgroundStyle
    button {
      target
      title
      url
    }
    extraClass
    heading
    hideSection
    subHeading
    gallery {
      altText
      gatsbyImage(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED, width: 200)
      mediaItemUrl
    }
  }
`;
