import { motion, useInView } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';

const customStylesVideo = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'transparent',
    border: '0',
    borderRadius: '0',
    padding: '0',
    width: '100%',
    height: '100%',
    zindex: '999',
  },
};

const HomeBanner = ({ module }) => {
  const [modalIsOpenVideo, setIsOpenVideo] = React.useState(false);
  const [destination, setDestination] = React.useState('');
  const [travel, setTravel] = React.useState('');

  function openModalVideo() {
    document.body.classList.add('video-modal-open');
    setIsOpenVideo(true);
  }
  function closeModalVideo() {
    document.body.classList.remove('video-modal-open');
    setIsOpenVideo(false);
  }

  const shortVideoUrl = module.shortVideoUrl;
  const imageFallback = module.backgroundImage.mediaItemUrl;
  const imgFallbackCDN = `https://wsrv.nl/?url=${imageFallback}&w=1495&h=800&fit=cover&output=webp`;

  const [background, setBackground] = useState(imageFallback);
  const [videoLoaded, setVideoLoaded] = useState(false);

  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0,
        ease: 'easeOut',
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  useEffect(() => {
    const video = document.getElementById('hero-video');
    if (video) {
      video.onloadeddata = () => {
        setVideoLoaded(true);
      };
    }
  }, [videoLoaded]);

  const query = useStaticQuery(graphql`
    query {
      options: allWpDestinationCategory {
        nodes {
          id
          name
        }
      }
      travelOptions: allWpTravel(
        filter: {
          travelStyleContent: { displayTravelOnHomePage: { eq: "yes" } }
        }
      ) {
        nodes {
          id
          title
        }
      }
      destinations: allWpDestination(
        sort: { order: DESC, fields: title }
        filter: { wpParent: { node: { id: { eq: null } } } }
      ) {
        nodes {
          id
          title
          wpChildren {
            nodes {
              id
              slug
              ... on WpDestination {
                id
                title
              }
            }
          }
        }
      }
    }
  `);
  var destinationValues =
    query.destinations.nodes.flatMap(({ wpChildren }) =>
      wpChildren.nodes.map((item) => ({ id: item.id, title: item.title }))
    ) || [];
  destinationValues = destinationValues.sort(function (a, b) {
    const nameA = a.title.toUpperCase(); // ignore upper and lowercase
    const nameB = b.title.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
  return (
    <>
      <section
        style={{ backgroundColor: `#f6f4f1` }}
        className={`banner h-screen min-h-[550px] relative ${
          module?.extraClass ? ' ' + module?.extraClass : ''
        }`}
      >
        {module?.extraClass === 'home-banner' && (
          <motion.div
            variants={item}
            className="home-banner__logo lgscreen:hidden absolute right-[60px] bottom-[90px] h-[150px] w-[150px] z-[99]"
          >
            <img
              src="/images/22ndLogo.webp"
              alt="Timeless Africa Safaris image"
              height={200}
              width={200}
              className="h-[150px] w-[150px] object-contain"
            />
          </motion.div>
        )}
        <div className="banner-img h-full flex items-end justify-start">
          <div className="banner-video absolute top-0 h-full w-full">
            <video
              id="hero-video"
              playsInline
              autoPlay
              muted
              loop
              poster={imgFallbackCDN}
            >
              {!videoLoaded && <source src={shortVideoUrl} type="video/mp4" />}
            </video>
          </div>
          <motion.div
            ref={ref}
            initial="hidden"
            animate={isInView || hasPlayed ? 'show' : 'hidden'}
            variants={container}
            className="banner-info px-15 text-left z-9 relative lg:pl-70 pb-120 w-full lg:w-auto lg:max-w-[850px] mdscreen:pb-[160px]"
          >
            {module?.extraClass === 'home-banner' && (
              <motion.div
                variants={item}
                className="home-banner__logo hidden lgscreen:flex  w-full justify-start lgscreen:justify-center pb-20 lgscreen:pb-0"
              >
                <img
                  src="/images/22ndLogo.png"
                  alt="Timeless Africa Safaris image"
                  height={150}
                  width={150}
                  className="mdscreen:h-[120px] mdscreen:w-[120px] object-contain"
                />
              </motion.div>
            )}
            {module?.videoUrl && (
              <motion.div variants={item} className="banner-video py-10">
                <button
                  onClick={() => {
                    openModalVideo();
                  }}
                  className="flex justify-start items-center"
                >
                  <div className="video-icon w-20 relative top-[-2px]">
                    <img
                      src="/images/play.png"
                      loading="eager"
                      className="m-auto"
                      alt="Play"
                    />
                  </div>
                  <span className="text-white font-secondary text-17 ml-10">
                    {module?.buttonCaption
                      ? module?.buttonCaption
                      : 'Watch Video'}
                  </span>
                </button>
              </motion.div>
            )}
            {module?.heading && (
              <motion.h1
                variants={item}
                className="lg:text-55 lg:leading-60 xlscreen:text-[45px] xlscreen:leading-45 lgscreen:text-35 mdscreen:text-26 mdscreen:leading-27 lgscreen:mb-5 text-white uppercase"
              >
                {module.heading}
              </motion.h1>
            )}
            {module?.description && parse(module?.description)}
            <motion.div variants={item} className="banner-form mt-20">
              <form>
                <div className="flex flex-wrap lg:mx-minus-20 mx-minus-10">
                  {destinationValues.length > 0 && (
                    <div className="form-group lg:px-20 px-10">
                      <select
                        onChange={(e) => {
                          setDestination(e.target.value);
                        }}
                      >
                        <option value="">Where would you like to go?</option>
                        {destinationValues.map((destination) => (
                          <option
                            key={destination.id}
                            value={destination.title}
                          >
                            {destination.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {query?.travelOptions?.nodes?.length > 0 && (
                    <div className="form-group lg:px-20 px-10">
                      <select
                        onChange={(e) => {
                          setTravel(e.target.value);
                        }}
                      >
                        <option>What are you interested in?</option>
                        {query.travelOptions.nodes.map((item) => (
                          <option key={item.title} value={item.title}>
                            {item.title}
                          </option>
                        ))}
                        <option value="">Other</option>
                      </select>
                    </div>
                  )}
                  <div className="btn-custom">
                    <a
                      href={`/trip?destination=${destination}&travel=${travel}`}
                      className="button banner-btn-transparent"
                    >
                      Search
                    </a>
                  </div>
                </div>
              </form>
            </motion.div>
          </motion.div>
        </div>
        <div className="banner-scroll-arrow-wrapper hidden mdscreen:flex flex-col items-center absolute bottom-[36px] right-[35%] z-[9]">
          <span className="scroll-arrow-text text-white italic font-secondary">
            Scroll To Discover
          </span>
          <img
            src={'images/scroll-down-arrow.svg'}
            loading="eager"
            alt="Timeless Africa Safaris image"
            className="banner-scroll-arrow h-[8px] mt-[7px] object-contain"
          />
        </div>
      </section>
      <React.Fragment>
        <Modal
          isOpen={modalIsOpenVideo}
          onRequestClose={closeModalVideo}
          style={customStylesVideo}
        >
          <div className="video-modal-popup h-full">
            <button
              onClick={closeModalVideo}
              className="absolute right-30 top-30 w-50 h-50 bg-white rounded-999 z-999 inline-block"
            >
              <img
                src="/images/modal-close.png"
                loading="lazy"
                className="m-auto w-20 h-20"
                alt="Close"
              />
            </button>
            <div className="modal-video h-full">
              {module?.videoUrl && module?.videoUrl !== '' ? (
                <ReactPlayer
                  className="modal-banner-video"
                  loop={true}
                  playing={true}
                  controls={true}
                  muted={true}
                  url={module.videoUrl}
                />
              ) : (
                <span>Video Not Found!</span>
              )}
            </div>
          </div>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default HomeBanner;
export const HomeBannerFragment = graphql`
  fragment HomeBannerFragment on WpPage_Pagecontent_PageContent_HomeBanner {
    id
    backgroundImage {
      altText
      gatsbyImage(
        formats: [AUTO, WEBP, AVIF]
        placeholder: BLURRED
        width: 1920
      )
      mediaItemUrl
    }
    bannerStyle
    buttonCaption
    description
    elephantImage {
      altText
      gatsbyImage(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED, width: 400)
    }
    extraClass
    heading
    hideSection
    videoUrl
    shortVideoUrl
  }
`;
