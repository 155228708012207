import { motion, useInView } from 'framer-motion';
import { Link, graphql } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';

const SimpleContentWithIcon = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };
  const container2 = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 1.1,
        staggerChildren: 0.2,
      },
    },
  };

  const aniItem = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  return (
    <section
      className={`common-content bg-pattern lg:py-60 py-30${
        module?.extraClass ? ' ' + module.extraClass : ''
      }`}
    >
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView || hasPlayed ? 'show' : 'hidden'}
        variants={container}
        className="w-[860px] m-auto text-center lgscreen:w-full px-20 fade-ani"
      >
        {module?.heading && (
          <motion.div variants={aniItem} className="title-black">
            <h3>{module?.heading}</h3>
          </motion.div>
        )}
        {module?.description && (
          <motion.div variants={aniItem} className="content ">
            {parse(module?.description)}
          </motion.div>
        )}
        {module?.link?.title && (
          <motion.div
            variants={aniItem}
            className="btn-custom mt-30 relative z-9 "
          >
            <Link
              to={module.link.url || '#'}
              className={
                !module?.linkStyle || module?.linkStyle === 'link'
                  ? 'button btn-red-border'
                  : 'button header-btn-red cursor-pointer'
              }
            >
              <span>{module.link.title}</span>
            </Link>
          </motion.div>
        )}
      </motion.div>

      <div className="container-fluid-xl lg:mt-50 mt-25 fade-ani">
        <motion.div
          initial="hidden"
          animate={isInView || hasPlayed ? 'show' : 'hidden'}
          variants={container2}
          className="flex flex-wrap justify-center gap-y-6 lg:mx-minus-20 lgscreen:gap-y-6 lgscreen:justify-start mx-0 items-center"
        >
          {module?.iconList?.length > 0 &&
            module.iconList.map((item, index) => (
              <motion.div
                variants={aniItem}
                className="md:w-3/12 lgscreen:w-50-per smscreen:w-full w-full md:px-20 px-0"
                key={index.toString()}
              >
                <div className="icon-grid flex items-center smscreen2:justify-center">
                  <div className="icon pr-15">
                    {item?.icon && (
                      <img
                        src={item?.icon?.mediaItemUrl}
                        loading="lazy"
                        alt={item?.icon?.altText}
                        className="max-w-[40px] fade-ani"
                      />
                    )}
                  </div>
                  {item?.content && (
                    <span className="uppercase text-black-500 font-heading text-15 mdscreen:text-12 max-w-[200px] fade-ani">
                      {item.content}
                    </span>
                  )}
                </div>
              </motion.div>
            ))}
        </motion.div>
      </div>
    </section>
  );
};

export default SimpleContentWithIcon;
export const SimpleContentWithIconFragment = graphql`
  fragment SimpleContentWithIconFragment on WpPage_Pagecontent_PageContent_SimpleContentWithIcon {
    id
    extraClass
    hideSection
    backgroundStyle
    heading
    description
    link {
      target
      title
      url
    }
    linkStyle
    iconList {
      content
      icon {
        altText
        mediaItemUrl
      }
    }
  }
`;
