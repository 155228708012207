import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import ImageOpt from './ImageOpt';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const OurNonProfitPartners = ({ module }) => {
  return (
    <section
      className={`zigzag landscape bx-img-content ${
        module?.backgroundStyle === 'background with pattern'
          ? 'bg-pattern'
          : ''
      } py-60${module?.extraClass ? ' ' + module.extraClass : ''}`}
    >
      <div className="container-fluid-xl">
        <div className="section-title text-center fade-ani">
          {module?.heading && (
            <div className="title-black fade-ani">
              <h3>{module.heading}</h3>
            </div>
          )}
          {module?.description && (
            <div className="content ">{parse(module.description)}</div>
          )}
        </div>
      </div>
      <div className="container-fluid bx-img-content-main  grid gap-y-20 mdscreen:gap-y-7 lg:mt-60 mt-30">
        {module.data?.map((item, index) => (
          <div className="flex flex-wrap items-center" key={index.toString()}>
            <div
              className={`lg:w-6/12 w-[100%] overflow-hidden${
                item?.imagePosition === 'right'
                  ? ' lg:order-2 right-img'
                  : ' left-img'
              }`}
            >
              {item?.image && item?.addSlider === 'no' ? (
                <GatsbyImage
                  image={getImage(item.image)}
                  alt={item.image?.altText}
                  className="img"
                />
              ) : (
                <div className="img-slider w-[44vw] lgscreen:w-[93vw] mdscreen:w-[90vw]">
                  <Swiper
                    spaceBetween={0}
                    loop={false}
                    slidesPerView={1}
                    allowTouchMove={true}
                    grabCursor={true}
                    speed={1000}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                  >
                    {item.slider?.length > 0 &&
                      item.slider.map((image, index) => (
                        <SwiperSlide key={index}>
                          <ImageOpt
                            imgData={image.mediaItemUrl}
                            imgAlt={image.altText}
                            imgLoad="eager"
                            width={1495}
                            height={1200}
                            imgClass="w-full"
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
              )}
            </div>
            <div className="lg:w-6/12 w-full">
              <div className="zigzag-content lg:pt-0 pt-30 fade-ani">
                {item?.heading && (
                  <div className="title-black ">
                    <h3>{item.heading}</h3>
                  </div>
                )}
                {item?.description && (
                  <div className="content global-list ">
                    {parse(item.description)}
                  </div>
                )}
                {item?.link && (
                  <div className="btn-custom flex flex-wrap items-start justify-start space-x-8 mt-30 relative z-9">
                    <Link
                      to={item.link?.url}
                      className="button header-btn-red cursor-pointer"
                    >
                      {item.link?.title}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default OurNonProfitPartners;
export const OurNonProfitPartnersFragment = graphql`
  fragment OurNonProfitPartnersFragment on WpPage_Pagecontent_PageContent_OurNonProfitPartners {
    id
    extraClass
    hideSection
    heading
    description
    backgroundStyle
    data {
      description
      fieldGroupName
      heading
      imagePosition
      addSlider
      slider {
        mediaItemUrl
        altText
      }
      image {
        altText
        gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
      }
    }
  }
`;
