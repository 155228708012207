import { motion, useInView } from 'framer-motion';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef, useState } from 'react';

const IconWithContent = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);
  return (
    <section
      className={`icon-with-content lg:py-50 py-25 lg:mb-0 mb-0${
        module?.extraClass ? ' ' + module.extraClass : ''
      }`}
    >
      <div className="container-fluid-xl fade-ani">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={isInView || hasPlayed ? 'show' : 'hidden'}
          variants={container}
          className="flex flex-wrap justify-center gap-y-20 lg:mx-minus-20 lgscreen:gap-y-10 lgscreen:justify-start mx-0 smscreen2:justify-center items-center"
        >
          {module?.iconWithContent?.length > 0 &&
            module.iconWithContent.map((iconContent, index) => (
              <motion.div
                variants={item}
                className="md:w-3/12 lgscreen:w-50-per smscreen:w-full w-full md:px-20 px-0 icon-grid-column "
                key={index.toString()}
              >
                <div className="icon-grid flex items-center ">
                  <div className="icon pr-15">
                    {iconContent?.icon && (
                      <GatsbyImage
                        image={getImage(iconContent.icon)}
                        alt={iconContent?.icon?.altText}
                        className="max-w-[40px]"
                      />
                    )}
                  </div>
                  {iconContent?.content && (
                    <span className="uppercase text-black-500 font-heading text-15 mdscreen:text-12">
                      {iconContent.content?.toString().trim()}
                    </span>
                  )}
                </div>
              </motion.div>
            ))}
        </motion.div>
      </div>
    </section>
  );
};

export default IconWithContent;
export const IconWithContentFragment = graphql`
  fragment IconWithContentFragment on WpPage_Pagecontent_PageContent_IconWithContent {
    id
    extraClass
    hideSection
    iconWithContent {
      content
      icon {
        altText
        gatsbyImage(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 200
        )
      }
    }
  }
`;
