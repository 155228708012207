import { motion, useInView } from 'framer-motion';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';

const TeamListing = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const aniItem = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  return (
    <section
      className={`team-members lg:py-100 py-30${
        module?.extraClass ? ' ' + module.extraClass : ''
      }`}
    >
      <div className="container-fluid">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={isInView || hasPlayed ? 'show' : 'hidden'}
          variants={container}
          className="flex flex-wrap lg:mx-minus-15 mx-0 gap-y-20 lgscreen:gap-y-10"
        >
          {module?.team?.length > 0 &&
            module.team.map((item, index) => (
              <motion.div
                variants={aniItem}
                className="lg:w-3/12 md:w-1/2 w-full md:px-15 px-0 fade-ani"
                key={index}
              >
                <div className="tm-bx">
                  {item?.featuredImage?.node && (
                    <GatsbyImage
                      image={getImage(item.featuredImage.node)}
                      alt={item.featuredImage.node.altText}
                    />
                  )}
                  <div className="tm-bx-content pt-30 lg:pr-30 pr-0">
                    {item?.teamContent?.meta?.position && (
                      <span className="uppercase text-12 text-black-500 mb-15 inline-block fade-ani">
                        {item.teamContent.meta.position}
                      </span>
                    )}
                    {item?.title && (
                      <div className="title-black ">
                        <h5>{item.title}</h5>
                      </div>
                    )}
                    {item?.content && (
                      <div className="content ">{parse(item.content)}</div>
                    )}
                    {item?.teamContent?.meta?.showReadMore === 'yes' && (
                      <Link to={item.uri}>
                        <div className="btn-custom flex flex-wrap items-center space-x-8 mt-30 relative z-9 ">
                          <span className="button btn-red-border">
                            Read More
                          </span>
                        </div>
                      </Link>
                    )}
                  </div>
                </div>
              </motion.div>
            ))}
        </motion.div>
      </div>
    </section>
  );
};

export default TeamListing;
export const TeamListingFragment = graphql`
  fragment TeamListingFragment on WpPage_Pagecontent_PageContent_TeamListing {
    id
    hideSection
    extraClass
    team {
      ... on WpTeam {
        id
        content
        title
        uri
        teamContent {
          meta {
            position
            showReadMore
          }
        }
        featuredImage {
          node {
            altText
            gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 500)
          }
        }
      }
    }
  }
`;
