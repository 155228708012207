import { graphql, Link } from "gatsby";
import React from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const PartnerTestimonial = ({ module }) => {
  return (
    <section className={`client-testimonials lg:py-100 py-30${module?.extraClass ? " " + module.extraClass : ""}`}>
      <div className='w-[735px] m-auto lgscreen:w-full lgscreen:px-30 text-center fade-ani'>
        {module?.heading && <span className='uppercase text-16 text-black-500 font-heading'>{module.heading}</span>}
        {
          module?.data?.length > 0 && (
            <div className='client-testimonials-slider mt-50 '>
              <Swiper
                spaceBetween={0}
                loop={false}
                slidesPerView={1}
                speed={300}
                grabCursor={true}
                pagination={{
                  clickable: true,
                }}
                navigation={false}
                modules={[Autoplay]}
                className="mySwiper"
              >
                {
                  module.data.map((item, index) => (
                    <SwiperSlide key={index.toString()}>
                      <div className='ct-content'>
                        <h4>
                          {item?.testimonial}
                        </h4>
                        <span className='uppercase text-14 text-black-500 mt-30 inline-block '>{item?.detail}</span>
                      </div>
                    </SwiperSlide>
                  ))
                }
              </Swiper>
            </div>
          )
        }
        {
          module?.button && (
            <div className="btn-custom mt-30 relative z-9 fade-ani">
              <Link to={module.button?.url} target={module.button?.target} className="button header-btn-red" >{module.button?.title}</Link>
            </div>
          )
        }
      </div>
    </section>
  )
}

export default PartnerTestimonial;
export const PartnerTestimonialFragment = graphql`
  fragment PartnerTestimonialFragment on WpPage_Pagecontent_PageContent_PartnerTestimonial {
    id
    extraClass
    hideSection
    heading
    data {
      detail
      testimonial
    }
    button {
      target
      title
      url
    }
  }
`;